import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "purchaseButton" ]

  purchase() {
    var stripe = Stripe('pk_test_JYBQQRaUVEtGiGiKmC6XRKFh00IIZclbhN');
    var checkoutButton = this.purchaseButtonTarget;
    checkoutButton.addEventListener('click', function() {
      stripe.redirectToCheckout({
        sessionId: checkoutButton.getAttribute("data-session-id")
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      });
    });
  }

  connect() {
    this.purchase()
  }
}
